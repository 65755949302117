<template>
  <div class="change-shop">
    <div class="content">
      <logo class="logo" />
      <div class="title">Let's connect your store!</div>
      <div class="con">
        <div class="model">
          <div v-if="store == 1">
            <img src="../../assets/img/shopify.svg">
            <div class="name">Connect Shopify</div>
            <div class="desc">
              Add your Shopify store URL to connect with {{ appName }}
            </div>
            <a-input
              v-model="shopifyDomainName"
              placeholder="e.g. my-shop.myshopify.com"
            />
          </div>
          <div v-else>
            <img src="../../assets/img/woocommerce.svg">
            <div class="name">Connect WooCommerce</div>
            <div class="desc">
              Add your WooCommerce store URL to connect with {{ appName }}
            </div>
            <a-input
              v-model="shopifyDomainName"
              placeholder="https://www.examplestore.com"
            />
          </div>
          <div class="btns">
            <a-button @click="back">Back</a-button>
            <a-button type="primary" @click="connect">Connect</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { shopifyUrlGet, woocommerceUrlGet } from '@/api/woocom'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      shopifyDomainName: '',
      store: '',
      shopId: ''
    }
  },
  mounted() {
    this.store = this.$route.query.store
    if (this.$route.query.shopId) {
      this.shopId = this.$route.query.shopId
    }
  },
  computed: {
    ...mapGetters('User', {
      appName: 'getAppName'
    })
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    async connect() {
      if (this.store === 1) {
        let res
        if (this.shopId) {
          res = await shopifyUrlGet({
            shopifyDomainName: this.shopifyDomainName,
            shopId: this.shopId
          })
        } else {
          res = await shopifyUrlGet({
            shopifyDomainName: this.shopifyDomainName
          })
        }
        const { data, code, msg } = res
        if (code === 200) {
          window.location.href = data.redirectUrl
        } else {
          this.$message.error(msg)
        }
      } else {
        let result
        if (this.shopId) {
          result = await woocommerceUrlGet({
            url: this.shopifyDomainName,
            shopId: this.shopId
          })
        } else {
          result = await woocommerceUrlGet({
            url: this.shopifyDomainName
          })
        }
        const { data, code, msg } = result
        if (code === 200) {
          window.location.href = data.redirectUrl
        } else {
          this.$message.error(msg)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.change-shop {
  height: 100vh;
  background: #f2f2f2;
  margin-top: -62px;
}
.content {
  width: 572px;
  margin: 0 auto;
  text-align: center;
  padding-top: 62px;
  .logo {
    width: 132px;
    height: 38px;
  }
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.6px;
    color: #1b1b1b;
    margin: 20px 0 32px 0;
  }
  .con {
    text-align: center;
    margin-bottom: 20px;
    background: white;
    padding: 24px;
    box-shadow: 0px 1px 3px rgba(148, 148, 148, 0.25),
      0px 2px 3px rgba(148, 148, 148, 0.15),
      0px 0px 2px rgba(148, 148, 148, 0.1);
    .model {
      width: 410px;
      margin: 0 auto;
      img {
        widows: 54px;
        height: 54px;
      }
      .name {
        margin-top: 16px;
        font-weight: bold;
        font-size: 19px;
      }
      .desc {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: #595959;
        margin: 10px 0;
      }
      .btns {
        margin-top: 30px;
        text-align: right;
        button {
          margin-left: 15px;
        }
      }
    }
  }
}
</style>
